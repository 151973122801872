body {
  background-color: #fff;
}

.button-default {
  border-radius: 3px;
}

header h1 {
  width: 100%;
  height: 56px;
  color: transparent;
  background: #F2F0E4 url("../images/v2/logo-scorimmo-noir.svg") no-repeat center center;
  background-size: 137px 40px;
  margin-bottom: 40px;
}

.container {
  max-width: 1660px;
}

.flash-message {
  margin-left: 0;
  margin-right: 0;
}
.flash-message .button-default {
  text-decoration: none;
  float: right;
}
.flash-message p {
  width: 92%;
}

input[type=text], input[type=number], input[type=url], input[type=password], input[type=email], input[type=phone], input[type=time], select[class=new-select] {
  height: 26px;
}

.--nowrap {
  white-space: nowrap;
}

.left-content, .right-content {
  width: 300px;
  height: fit-content;
  position: sticky;
  top: 90px;
}
.left-content.--scrolled, .right-content.--scrolled {
  top: 10px;
}
.left-content .transparent, .right-content .transparent {
  height: 200px;
  width: 1px;
}

.left-content {
  z-index: 50;
}

.right-content {
  margin-left: 40px;
}
.right-content .transparent {
  height: 220px;
}

.middle-content {
  width: 100%;
  max-width: 980px;
  margin-left: 40px;
  height: fit-content;
}
.middle-content .primary {
  max-width: 640px;
  width: 100%;
}
.middle-content .secondary {
  max-width: 300px;
  margin: 0 10px 10px 40px;
}

.select2-container {
  background-color: #fff;
  box-shadow: 1px 2px 2px 0 rgba(0, 0, 0, 0.45);
}
.select2-container .select2-choice {
  border-radius: 0;
}
.select2-container .select2-choice > .select2-chosen {
  max-width: 69%;
  min-width: 0;
  text-overflow: ellipsis;
  overflow: hidden;
}
.select2-container .select2-choice.select2-default .select2-chosen {
  color: #bbb;
}
.select2-container.select2-container-disabled .select2-choice {
  border: 0;
  background-color: #ddd;
}
.select2-container.select2-container-disabled .select2-choice .select2-arrow {
  background-color: #fff;
}
.select2-container.select2-container-disabled .select2-choice > .select2-chosen {
  color: rgb(84, 84, 84);
}
.select2-container.select2-container-disabled .select2-choice .select2-arrow {
  background-color: #ddd;
}

input[type=text], input[type=email], input[type=url], input[type=number], input[type=time], select[class=new-select], select[class=number], textarea, .select2-container {
  box-shadow: 1px 2px 2px 0 rgba(0, 0, 0, 0.45);
}
input[type=text]:disabled, input[type=email]:disabled, input[type=url]:disabled, input[type=number]:disabled, input[type=time]:disabled, select[class=new-select]:disabled, select[class=number]:disabled, textarea:disabled, .select2-container:disabled {
  background-color: #ddd;
  color: rgb(84, 84, 84);
}

.lead-form .lead-form-loader, .contact-form .lead-form-loader {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.8) url("../images/loader.svg") no-repeat center center;
  background-size: 100px;
  z-index: 1500;
}
.lead-form .pos-name, .contact-form .pos-name {
  text-align: center;
  font-weight: bold;
  font-size: 16px;
  height: 40px;
  line-height: 40px;
  background-color: rgba(0, 1, 107, 0.4);
}
.lead-form .pos-name.--other, .contact-form .pos-name.--other {
  background-color: rgb(204, 204, 204);
}
.lead-form .pos-name.--wcb, .contact-form .pos-name.--wcb {
  background-color: rgba(255, 75, 0, 0.6);
}
.lead-form .pos-name.--jda, .contact-form .pos-name.--jda {
  background-color: rgba(241, 204, 0, 0.6);
}
.lead-form .affected, .contact-form .affected {
  background: rgba(255, 153, 162, 0.4);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  font-weight: bold;
  font-size: 15px;
}
.lead-form .form-line, .contact-form .form-line {
  margin-bottom: 20px;
  width: 100%;
}
.lead-form .form-line.--zipcode, .contact-form .form-line.--zipcode {
  width: 125px;
}
.lead-form .form-line.--quarter, .contact-form .form-line.--quarter {
  flex-basis: calc(24.5% - 40px);
}
.lead-form .form-line.--quarter .select2-container, .contact-form .form-line.--quarter .select2-container {
  max-width: 123px;
}
.lead-form .form-line.--quarter:first-child, .contact-form .form-line.--quarter:first-child {
  flex-basis: calc(27% - 40px);
}
.lead-form .form-line.--two-quarter, .contact-form .form-line.--two-quarter {
  flex-basis: calc(49% - 40px);
}
.lead-form .form-line.--three-quarter, .contact-form .form-line.--three-quarter {
  flex-basis: calc(73% - 40px);
}
.lead-form .form-line.--half, .contact-form .form-line.--half {
  flex-basis: calc(50% - 40px);
}
.lead-form .form-line.phone, .contact-form .form-line.phone {
  position: relative;
}
.lead-form .form-line.phone .call-number, .contact-form .form-line.phone .call-number {
  font-size: 0;
  position: absolute;
  top: 4px;
  right: 2px;
}
.lead-form .form-line.phone .call-number .button-icon, .contact-form .form-line.phone .call-number .button-icon {
  font-size: 14px;
  display: inline-block;
}
.lead-form .form-line.former .switch-container, .contact-form .form-line.former .switch-container {
  margin-left: 0;
}
.lead-form .--flex .form-line, .contact-form .--flex .form-line {
  margin-right: 40px;
}
.lead-form .--flex.--g-20 .form-line, .contact-form .--flex.--g-20 .form-line {
  margin-right: 20px;
}
.lead-form .section h3, .contact-form .section h3 {
  margin-bottom: 0;
}
.lead-form .section .section-content, .contact-form .section .section-content {
  margin-top: 15px;
}
.lead-form .section.--comments header .button-default, .contact-form .section.--comments header .button-default {
  width: 100px;
  padding: 0;
  margin: 0 auto 20px;
  font-size: 12px;
  text-align: center;
  position: absolute;
  line-height: 19px;
  font-weight: 500;
}
.lead-form .properties .button-default, .contact-form .properties .button-default {
  width: fit-content;
  display: block;
  text-align: center;
  color: white;
  background-color: rgb(0, 1, 107);
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  cursor: pointer;
  padding: 0 13px;
  line-height: 19px;
  font-size: 13.3px;
  margin: 0;
}
.lead-form .property .link, .contact-form .property .link {
  font-size: 16px;
  margin-top: 3px;
  margin-left: 5px;
}
.lead-form .property:only-of-type .remove-button-wrapper, .contact-form .property:only-of-type .remove-button-wrapper {
  display: none;
}
.lead-form .property:not(:first-child), .contact-form .property:not(:first-child) {
  border-top: 1px solid rgb(204, 204, 204);
  padding-top: 20px;
}
.lead-form .property .content, .contact-form .property .content {
  width: 100%;
}
.lead-form .sliders .switch-container, .contact-form .sliders .switch-container {
  margin-left: 0;
}
.lead-form .switch-container, .contact-form .switch-container {
  display: inline-block;
}
.lead-form .multiple .form-line:not(:first-child), .contact-form .multiple .form-line:not(:first-child) {
  margin-left: 20px;
}
.lead-form .multiple .form-line, .contact-form .multiple .form-line {
  margin-right: 0;
}
.lead-form .select2, .contact-form .select2 {
  max-width: 100%;
}
.lead-form .buttons, .contact-form .buttons {
  bottom: 0;
  position: fixed;
  width: 260px;
  border: solid 1px rgba(120, 120, 120, 0.4);
}
.lead-form .buttons .button-default, .contact-form .buttons .button-default {
  width: 200px;
  padding: 2px 10px;
  margin: 0 auto 20px;
  font-size: 14px;
  text-align: center;
}
.lead-form .buttons a.button-default, .contact-form .buttons a.button-default {
  width: 180px;
}
.lead-form .buttons .button-reopen-lead, .contact-form .buttons .button-reopen-lead {
  line-height: inherit;
  top: auto;
  display: block;
  padding-left: 0;
  padding-right: 0;
  text-align: center;
}
.lead-form .other .button-default, .contact-form .other .button-default {
  width: 200px;
  padding: 2px 10px;
  margin: 0 auto 20px;
  font-size: 14px;
  text-align: center;
}
.lead-form .other a.button-default, .contact-form .other a.button-default {
  width: 180px;
}
.lead-form .email-buttons button, .contact-form .email-buttons button {
  margin: 0 10px;
}
.lead-form .left-content .section-content, .contact-form .left-content .section-content {
  line-height: 18px;
}
.lead-form #lead_info .address, .lead-form #lead_info .contact-info, .contact-form #lead_info .address, .contact-form #lead_info .contact-info {
  margin-bottom: 20px;
}
.lead-form #lead_info .contact-info, .contact-form #lead_info .contact-info {
  width: 100%;
}
.lead-form #lead_info .contact-info .button-icon, .contact-form #lead_info .contact-info .button-icon {
  font-size: 12px;
  display: inline-block;
  width: 16px;
  height: 16px;
}
.lead-form #lead_info .contact-info .link, .contact-form #lead_info .contact-info .link {
  font-size: 16px;
  width: 25px;
  height: 25px;
}
.lead-form .hours th, .contact-form .hours th {
  font-weight: normal;
  text-align: left;
  padding-right: 20px;
}
.lead-form .hours .closed, .contact-form .hours .closed {
  color: rgb(238, 52, 50);
  text-align: center;
}
.lead-form .hours .current, .contact-form .hours .current {
  background-color: #BEF0E0;
}
.lead-form .hours .current.--closed, .contact-form .hours .current.--closed {
  background-color: rgba(238, 52, 50, 0.2);
}
.lead-form #lead_cities table, .contact-form #lead_cities table {
  width: 100%;
}
.lead-form #meteo, .contact-form #meteo {
  background: none;
  width: 60px;
  float: right;
  margin-left: 20px;
  text-align: right;
}
.lead-form #meteo .temp, .contact-form #meteo .temp {
  font-size: 10px;
  width: auto;
  display: block;
  line-height: 10px;
  color: rgb(119, 119, 119);
}
.lead-form .pos-note, .contact-form .pos-note {
  padding: 10px 0;
  border-top: 1px solid #ccc;
}
.lead-form .pos-note.important, .contact-form .pos-note.important {
  background: rgba(241, 204, 0, 0.2);
}
.lead-form .pos-note__date, .contact-form .pos-note__date {
  font-size: 10px;
  color: rgb(119, 119, 119);
}
.lead-form .history .common-loader img, .contact-form .history .common-loader img {
  width: 50px;
}
.lead-form .history .content, .contact-form .history .content {
  padding-left: 0;
  padding-right: 0;
}
.lead-form #lead_interest, .contact-form #lead_interest {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  gap: 10px;
  padding-bottom: 20px;
}
.lead-form #lead_interest input[type=radio], .contact-form #lead_interest input[type=radio] {
  opacity: 0;
  position: fixed;
  width: 0;
}
.lead-form #lead_interest input[type=radio]:active:not(:disabled) + label, .contact-form #lead_interest input[type=radio]:active:not(:disabled) + label {
  box-shadow: inset 0 4px 4px rgba(0, 0, 0, 0.25);
}
.lead-form #lead_interest input[type=radio]:disabled + label, .contact-form #lead_interest input[type=radio]:disabled + label {
  cursor: default;
}
.lead-form #lead_interest label, .contact-form #lead_interest label {
  align-self: stretch;
  flex: 1;
  background-color: #ccc;
  color: #fff;
  font-family: BebasNeue, sans-serif;
  font-size: 20px;
  line-height: 20px;
  border-radius: 5px;
  padding: 10px 20px;
  height: 40px;
  text-align: center;
  text-transform: uppercase;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.lead-form #lead_interest label.required:after, .contact-form #lead_interest label.required:after {
  content: "";
}
.lead-form #lead_interest label:hover, .contact-form #lead_interest label:hover {
  background-color: #dfd;
}
.lead-form #lead_interest_TRANSACTION:hover + label, .lead-form #lead_interest_TRANSACTION:active + label, .contact-form #lead_interest_TRANSACTION:hover + label, .contact-form #lead_interest_TRANSACTION:active + label {
  background-color: rgba(117, 168, 151, 0.4);
}
.lead-form #lead_interest_TRANSACTION:checked + label, .contact-form #lead_interest_TRANSACTION:checked + label {
  background-color: #75A897;
}
.lead-form #lead_interest_LOCATION:hover + label, .lead-form #lead_interest_LOCATION:active + label, .contact-form #lead_interest_LOCATION:hover + label, .contact-form #lead_interest_LOCATION:active + label {
  background-color: rgba(31, 48, 73, 0.4);
}
.lead-form #lead_interest_LOCATION:checked + label, .contact-form #lead_interest_LOCATION:checked + label {
  background-color: rgb(0, 1, 107);
}
.lead-form #lead_interest_CONSTRUCTION:hover + label, .lead-form #lead_interest_CONSTRUCTION:active + label, .contact-form #lead_interest_CONSTRUCTION:hover + label, .contact-form #lead_interest_CONSTRUCTION:active + label {
  background-color: rgba(228, 200, 86, 0.4);
}
.lead-form #lead_interest_CONSTRUCTION:checked + label, .contact-form #lead_interest_CONSTRUCTION:checked + label {
  background-color: #E4C856;
}
.lead-form #lead_interest_PROMOTION:hover + label, .lead-form #lead_interest_PROMOTION:active + label, .contact-form #lead_interest_PROMOTION:hover + label, .contact-form #lead_interest_PROMOTION:active + label {
  background-color: rgba(213, 135, 42, 0.4);
}
.lead-form #lead_interest_PROMOTION:checked + label, .contact-form #lead_interest_PROMOTION:checked + label {
  background-color: rgb(213, 135, 42);
}
.lead-form #lead_interest_SYNDIC:hover + label, .lead-form #lead_interest_SYNDIC:active + label, .contact-form #lead_interest_SYNDIC:hover + label, .contact-form #lead_interest_SYNDIC:active + label {
  background-color: rgba(201, 58, 58, 0.4);
}
.lead-form #lead_interest_SYNDIC:checked + label, .contact-form #lead_interest_SYNDIC:checked + label {
  background-color: #C93A3A;
}
.lead-form #lead_interest_NOTAIRE:hover + label, .lead-form #lead_interest_NOTAIRE:active + label, .contact-form #lead_interest_NOTAIRE:hover + label, .contact-form #lead_interest_NOTAIRE:active + label {
  background-color: rgba(0, 122, 151, 0.4);
}
.lead-form #lead_interest_NOTAIRE:checked + label, .contact-form #lead_interest_NOTAIRE:checked + label {
  background-color: rgb(0, 122, 151);
}
.lead-form #lead_interest_VIAGER:hover + label, .lead-form #lead_interest_VIAGER:active + label, .contact-form #lead_interest_VIAGER:hover + label, .contact-form #lead_interest_VIAGER:active + label {
  background-color: rgba(190, 184, 0, 0.4);
}
.lead-form #lead_interest_VIAGER:checked + label, .contact-form #lead_interest_VIAGER:checked + label {
  background-color: rgb(190, 184, 0);
}
.lead-form #lead_interest_ENTREPRISE:hover + label, .lead-form #lead_interest_ENTREPRISE:active + label, .contact-form #lead_interest_ENTREPRISE:hover + label, .contact-form #lead_interest_ENTREPRISE:active + label {
  background-color: rgba(170, 16, 84, 0.4);
}
.lead-form #lead_interest_ENTREPRISE:checked + label, .contact-form #lead_interest_ENTREPRISE:checked + label {
  background-color: rgb(170, 16, 84);
}
.lead-form #lead_interest_ADMINISTRATIF:hover + label, .lead-form #lead_interest_ADMINISTRATIF:active + label, .contact-form #lead_interest_ADMINISTRATIF:hover + label, .contact-form #lead_interest_ADMINISTRATIF:active + label {
  background-color: rgba(51, 51, 51, 0.4);
}
.lead-form #lead_interest_ADMINISTRATIF:checked + label, .contact-form #lead_interest_ADMINISTRATIF:checked + label {
  background-color: #333;
}
.lead-form #lead_purpose label, .contact-form #lead_purpose label {
  display: none;
}
.lead-form .by-interest, .contact-form .by-interest {
  display: none;
}
.lead-form .by-interest.--email, .lead-form .by-interest.--call, .contact-form .by-interest.--email, .contact-form .by-interest.--call {
  display: block;
}
.lead-form .inquiries, .contact-form .inquiries {
  flex-wrap: wrap;
}
.lead-form .inquiries .form-line, .contact-form .inquiries .form-line {
  flex: 0 0 calc(50% - 40px);
  box-sizing: border-box;
}
.lead-form .inquiries .select2-container .select2-choice > .select2-chosen, .contact-form .inquiries .select2-container .select2-choice > .select2-chosen {
  max-width: 100%;
}
.lead-form[data-interest=TRANSACTION] .interest-TRANSACTION, .lead-form[data-interest=TRANSACTION] .interest-all, .contact-form[data-interest=TRANSACTION] .interest-TRANSACTION, .contact-form[data-interest=TRANSACTION] .interest-all {
  display: block;
}
.lead-form[data-interest=TRANSACTION] .interest-TRANSACTION.--flex, .lead-form[data-interest=TRANSACTION] .interest-all.--flex, .contact-form[data-interest=TRANSACTION] .interest-TRANSACTION.--flex, .contact-form[data-interest=TRANSACTION] .interest-all.--flex {
  display: flex;
}
.lead-form[data-interest=TRANSACTION] #lead_purpose input.interest-TRANSACTION + label, .contact-form[data-interest=TRANSACTION] #lead_purpose input.interest-TRANSACTION + label {
  display: inline-block;
}
.lead-form[data-interest=LOCATION] .interest-LOCATION, .lead-form[data-interest=LOCATION] .interest-all, .contact-form[data-interest=LOCATION] .interest-LOCATION, .contact-form[data-interest=LOCATION] .interest-all {
  display: block;
}
.lead-form[data-interest=LOCATION] .interest-LOCATION.--flex, .lead-form[data-interest=LOCATION] .interest-all.--flex, .contact-form[data-interest=LOCATION] .interest-LOCATION.--flex, .contact-form[data-interest=LOCATION] .interest-all.--flex {
  display: flex;
}
.lead-form[data-interest=LOCATION] #lead_purpose input.interest-LOCATION + label, .contact-form[data-interest=LOCATION] #lead_purpose input.interest-LOCATION + label {
  display: inline-block;
}
.lead-form[data-interest=CONSTRUCTION] .interest-CONSTRUCTION, .lead-form[data-interest=CONSTRUCTION] .interest-all, .contact-form[data-interest=CONSTRUCTION] .interest-CONSTRUCTION, .contact-form[data-interest=CONSTRUCTION] .interest-all {
  display: block;
}
.lead-form[data-interest=CONSTRUCTION] .interest-CONSTRUCTION.--flex, .lead-form[data-interest=CONSTRUCTION] .interest-all.--flex, .contact-form[data-interest=CONSTRUCTION] .interest-CONSTRUCTION.--flex, .contact-form[data-interest=CONSTRUCTION] .interest-all.--flex {
  display: flex;
}
.lead-form[data-interest=CONSTRUCTION] #lead_purpose input.interest-CONSTRUCTION + label, .contact-form[data-interest=CONSTRUCTION] #lead_purpose input.interest-CONSTRUCTION + label {
  display: inline-block;
}
.lead-form[data-interest=PROMOTION] .interest-PROMOTION, .lead-form[data-interest=PROMOTION] .interest-all, .contact-form[data-interest=PROMOTION] .interest-PROMOTION, .contact-form[data-interest=PROMOTION] .interest-all {
  display: block;
}
.lead-form[data-interest=PROMOTION] .interest-PROMOTION.--flex, .lead-form[data-interest=PROMOTION] .interest-all.--flex, .contact-form[data-interest=PROMOTION] .interest-PROMOTION.--flex, .contact-form[data-interest=PROMOTION] .interest-all.--flex {
  display: flex;
}
.lead-form[data-interest=PROMOTION] #lead_purpose input.interest-PROMOTION + label, .contact-form[data-interest=PROMOTION] #lead_purpose input.interest-PROMOTION + label {
  display: inline-block;
}
.lead-form[data-interest=SYNDIC] .interest-SYNDIC, .lead-form[data-interest=SYNDIC] .interest-all, .contact-form[data-interest=SYNDIC] .interest-SYNDIC, .contact-form[data-interest=SYNDIC] .interest-all {
  display: block;
}
.lead-form[data-interest=SYNDIC] .interest-SYNDIC.--flex, .lead-form[data-interest=SYNDIC] .interest-all.--flex, .contact-form[data-interest=SYNDIC] .interest-SYNDIC.--flex, .contact-form[data-interest=SYNDIC] .interest-all.--flex {
  display: flex;
}
.lead-form[data-interest=SYNDIC] #lead_purpose input.interest-SYNDIC + label, .contact-form[data-interest=SYNDIC] #lead_purpose input.interest-SYNDIC + label {
  display: inline-block;
}
.lead-form[data-interest=NOTAIRE] .interest-NOTAIRE, .lead-form[data-interest=NOTAIRE] .interest-all, .contact-form[data-interest=NOTAIRE] .interest-NOTAIRE, .contact-form[data-interest=NOTAIRE] .interest-all {
  display: block;
}
.lead-form[data-interest=NOTAIRE] .interest-NOTAIRE.--flex, .lead-form[data-interest=NOTAIRE] .interest-all.--flex, .contact-form[data-interest=NOTAIRE] .interest-NOTAIRE.--flex, .contact-form[data-interest=NOTAIRE] .interest-all.--flex {
  display: flex;
}
.lead-form[data-interest=NOTAIRE] #lead_purpose input.interest-NOTAIRE + label, .contact-form[data-interest=NOTAIRE] #lead_purpose input.interest-NOTAIRE + label {
  display: inline-block;
}
.lead-form[data-interest=VIAGER] .interest-VIAGER, .lead-form[data-interest=VIAGER] .interest-all, .contact-form[data-interest=VIAGER] .interest-VIAGER, .contact-form[data-interest=VIAGER] .interest-all {
  display: block;
}
.lead-form[data-interest=VIAGER] .interest-VIAGER.--flex, .lead-form[data-interest=VIAGER] .interest-all.--flex, .contact-form[data-interest=VIAGER] .interest-VIAGER.--flex, .contact-form[data-interest=VIAGER] .interest-all.--flex {
  display: flex;
}
.lead-form[data-interest=VIAGER] #lead_purpose input.interest-VIAGER + label, .contact-form[data-interest=VIAGER] #lead_purpose input.interest-VIAGER + label {
  display: inline-block;
}
.lead-form[data-interest=ENTREPRISE] .interest-ENTREPRISE, .lead-form[data-interest=ENTREPRISE] .interest-all, .contact-form[data-interest=ENTREPRISE] .interest-ENTREPRISE, .contact-form[data-interest=ENTREPRISE] .interest-all {
  display: block;
}
.lead-form[data-interest=ENTREPRISE] .interest-ENTREPRISE.--flex, .lead-form[data-interest=ENTREPRISE] .interest-all.--flex, .contact-form[data-interest=ENTREPRISE] .interest-ENTREPRISE.--flex, .contact-form[data-interest=ENTREPRISE] .interest-all.--flex {
  display: flex;
}
.lead-form[data-interest=ENTREPRISE] #lead_purpose input.interest-ENTREPRISE + label, .contact-form[data-interest=ENTREPRISE] #lead_purpose input.interest-ENTREPRISE + label {
  display: inline-block;
}
.lead-form[data-interest=ADMINISTRATIF] .interest-ADMINISTRATIF, .lead-form[data-interest=ADMINISTRATIF] .interest-all, .contact-form[data-interest=ADMINISTRATIF] .interest-ADMINISTRATIF, .contact-form[data-interest=ADMINISTRATIF] .interest-all {
  display: block;
}
.lead-form[data-interest=ADMINISTRATIF] .interest-ADMINISTRATIF.--flex, .lead-form[data-interest=ADMINISTRATIF] .interest-all.--flex, .contact-form[data-interest=ADMINISTRATIF] .interest-ADMINISTRATIF.--flex, .contact-form[data-interest=ADMINISTRATIF] .interest-all.--flex {
  display: flex;
}
.lead-form[data-interest=ADMINISTRATIF] #lead_purpose input.interest-ADMINISTRATIF + label, .contact-form[data-interest=ADMINISTRATIF] #lead_purpose input.interest-ADMINISTRATIF + label {
  display: inline-block;
}
.lead-form .record-section, .contact-form .record-section {
  position: relative;
}
.lead-form .record-section .overlay, .contact-form .record-section .overlay {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  background: rgba(0, 0, 0, 0.8) url("../images/loader.svg") no-repeat center center;
  background-size: 50px;
  z-index: 10;
  border-radius: 5px;
}
.lead-form .new-players-section .call-player-select, .contact-form .new-players-section .call-player-select {
  width: 220px;
  margin-right: 10px;
}
.lead-form .switch-container.record, .contact-form .switch-container.record {
  margin: 0 20px;
}
.lead-form .call-player-wrapper .--flex, .contact-form .call-player-wrapper .--flex {
  margin-bottom: 10px;
}
.lead-form .answer textarea, .contact-form .answer textarea {
  height: 200px;
}
.lead-form .breadcrumb-notes .note, .contact-form .breadcrumb-notes .note {
  background: #DDDDDD;
  border-radius: 5px;
  color: #555555;
  padding: 5px 15px;
  line-height: 18px;
  margin-bottom: 20px;
}
.lead-form .breadcrumb-notes .note .date-author, .contact-form .breadcrumb-notes .note .date-author {
  color: rgba(85, 85, 85, 0.5);
  font-size: 10px;
}
.lead-form .sort-search.--current, .contact-form .sort-search.--current {
  border-bottom: 2px solid rgb(211, 27, 27) !important;
  background-color: rgba(211, 27, 27, 0.1);
}
.lead-form #lead_team .section-content, .contact-form #lead_team .section-content {
  min-width: 860px;
}
.lead-form #lead_team .errors, .contact-form #lead_team .errors {
  margin-left: 20px;
  margin-bottom: 10px;
}
.lead-form .sellers, .contact-form .sellers {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.lead-form .sellers .header, .contact-form .sellers .header {
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 10px;
}
.lead-form .sellers .header .prioritization, .contact-form .sellers .header .prioritization {
  color: rgb(238, 52, 50);
  margin-left: 15px;
  margin-right: 5px;
}
.lead-form .sellers .content, .contact-form .sellers .content {
  line-height: 16px;
}
.lead-form .sellers .content .seller-line, .contact-form .sellers .content .seller-line {
  padding: 10px 20px;
  flex-wrap: wrap;
  cursor: pointer;
}
.lead-form .sellers .content .seller-line:not(:first-child), .contact-form .sellers .content .seller-line:not(:first-child) {
  border-top: 1px solid rgb(204, 204, 204);
}
.lead-form .sellers .seller-line:hover, .contact-form .sellers .seller-line:hover {
  background-color: rgba(190, 240, 224, 0.4);
}
.lead-form .sellers .seller-selected, .lead-form .sellers .seller-selected:hover, .contact-form .sellers .seller-selected, .contact-form .sellers .seller-selected:hover {
  background-color: rgba(190, 240, 224, 0.8);
}
.lead-form .sellers .seller-line > div, .lead-form .sellers .header > div, .contact-form .sellers .seller-line > div, .contact-form .sellers .header > div {
  margin-right: 1%;
}
.lead-form .sellers .seller-line__info, .lead-form .sellers .header__info, .contact-form .sellers .seller-line__info, .contact-form .sellers .header__info {
  width: 31%;
  display: inline-flex;
  gap: 5px;
}
.lead-form .sellers .seller-line__pos, .lead-form .sellers .header__pos, .contact-form .sellers .seller-line__pos, .contact-form .sellers .header__pos {
  display: none;
}
.lead-form .sellers .seller-line__affect-code, .lead-form .sellers .header__affect-code, .contact-form .sellers .seller-line__affect-code, .contact-form .sellers .header__affect-code {
  width: 7%;
}
.lead-form .sellers .seller-line__sector, .lead-form .sellers .header__sector, .contact-form .sellers .seller-line__sector, .contact-form .sellers .header__sector {
  width: 15%;
}
.lead-form .sellers .seller-line__source, .lead-form .sellers .header__source, .contact-form .sellers .seller-line__source, .contact-form .sellers .header__source {
  width: 14%;
}
.lead-form .sellers .seller-line__phone, .lead-form .sellers .header__phone, .contact-form .sellers .seller-line__phone, .contact-form .sellers .header__phone {
  width: 14%;
}
.lead-form .sellers .seller-line__last_lead, .lead-form .sellers .header__last_lead, .contact-form .sellers .seller-line__last_lead, .contact-form .sellers .header__last_lead {
  width: 12%;
  text-align: center;
}
.lead-form .sellers .seller-line__position, .lead-form .sellers .header__position, .contact-form .sellers .seller-line__position, .contact-form .sellers .header__position {
  width: 5%;
  text-align: center;
}
.lead-form .sellers .seller-line__days-off, .lead-form .sellers .header__days-off, .contact-form .sellers .seller-line__days-off, .contact-form .sellers .header__days-off {
  width: 100%;
}
.lead-form .sellers .seller-line__leaves, .lead-form .sellers .header__leaves, .contact-form .sellers .seller-line__leaves, .contact-form .sellers .header__leaves {
  width: 100%;
  color: #EE3432 !important;
  font-size: 11px;
}
.lead-form .sellers.--group .seller-line__pos, .lead-form .sellers.--group .header__pos, .contact-form .sellers.--group .seller-line__pos, .contact-form .sellers.--group .header__pos {
  width: 14%;
  display: block;
}
.lead-form .sellers.--group .seller-line__info, .lead-form .sellers.--group .header__info, .contact-form .sellers.--group .seller-line__info, .contact-form .sellers.--group .header__info {
  width: 25%;
}
.lead-form .sellers.--group .seller-line__affect-code, .lead-form .sellers.--group .header__affect-code, .contact-form .sellers.--group .seller-line__affect-code, .contact-form .sellers.--group .header__affect-code {
  width: 7%;
}
.lead-form .sellers.--group .seller-line__sector, .lead-form .sellers.--group .header__sector, .contact-form .sellers.--group .seller-line__sector, .contact-form .sellers.--group .header__sector {
  width: 12%;
}
.lead-form .sellers.--group .seller-line__source, .lead-form .sellers.--group .header__source, .contact-form .sellers.--group .seller-line__source, .contact-form .sellers.--group .header__source {
  width: 12%;
}
.lead-form .sellers.--group .seller-line__phone, .lead-form .sellers.--group .header__phone, .contact-form .sellers.--group .seller-line__phone, .contact-form .sellers.--group .header__phone {
  width: 12%;
}
.lead-form .sellers.--group .seller-line__last_lead, .lead-form .sellers.--group .header__last_lead, .contact-form .sellers.--group .seller-line__last_lead, .contact-form .sellers.--group .header__last_lead {
  width: 7%;
  text-align: center;
}
.lead-form .sellers .header .actions, .contact-form .sellers .header .actions {
  margin-left: 5px;
}
.lead-form .sellers .header .actions span, .contact-form .sellers .header .actions span {
  color: rgba(119, 119, 119, 0.4);
  cursor: pointer;
}
.lead-form .sellers .header .actions span.--current, .contact-form .sellers .header .actions span.--current {
  color: rgb(119, 119, 119);
}
.lead-form .sellers .absent .leaves, .lead-form .sellers .absent .seller-identity, .contact-form .sellers .absent .leaves, .contact-form .sellers .absent .seller-identity {
  color: #EE3432 !important;
}
.lead-form .sellers .phone-link a, .lead-form .sellers .email-link a, .contact-form .sellers .phone-link a, .contact-form .sellers .email-link a {
  color: rgb(0, 1, 107);
  font-size: 12px;
  display: inline-block;
  width: 16px;
  height: 16px;
}
.lead-form .sellers .comments, .contact-form .sellers .comments {
  color: rgb(0, 1, 107);
  font-size: 11px;
}
.lead-form .sellers .leaves, .lead-form .sellers .role, .contact-form .sellers .leaves, .contact-form .sellers .role {
  color: rgb(119, 119, 119);
  font-size: 11px;
}
.lead-form .sellers .role, .contact-form .sellers .role {
  font-style: italic;
}
.lead-form .sellers.--prioritization .seller-line__info, .lead-form .sellers.--prioritization .header__info, .contact-form .sellers.--prioritization .seller-line__info, .contact-form .sellers.--prioritization .header__info {
  width: 26%;
}
.lead-form .sellers.--prioritization.--group .seller-line__info, .lead-form .sellers.--prioritization.--group .header__info, .contact-form .sellers.--prioritization.--group .seller-line__info, .contact-form .sellers.--prioritization.--group .header__info {
  width: 23%;
}
.lead-form ul.errors li, .contact-form ul.errors li {
  line-height: 1.5em;
  margin-top: 0.5em;
}

.email-no-content {
  margin: 20px;
  text-align: center;
}

span.tag {
  -moz-border-radius: 6px;
  border-radius: 6px;
  -webkit-border-radius: 6px;
  border: 1px solid rgba(120, 120, 120, 0.1);
  background-color: rgba(100, 100, 100, 0.05);
  padding: 0 2px;
  margin: 0 2px 2px 0;
  white-space: nowrap;
  display: inline-block;
}

.confirmation {
  width: 620px;
  margin: 0 auto;
  position: absolute;
  top: calc(50% - 65px);
  left: 50%;
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}
.confirmation h3 {
  margin-bottom: 40px;
}
.confirmation .section-content {
  text-align: center;
}
.confirmation .button-default {
  width: fit-content;
  padding: 2px 20px;
  margin: 40px auto 20px;
  font-size: 14px;
}

.ui-autocomplete {
  max-width: 500px;
}
.ui-autocomplete .ui-menu-item a {
  cursor: pointer;
}

.ui-widget .ui-menu-item {
  padding: 0 5px;
  background: rgb(243, 243, 243);
  border-radius: 3px;
  margin-bottom: 0;
  transition: all ease-out 0.2s;
}

.ui-widget .ui-menu-item:hover {
  background: rgba(190, 240, 224, 0.4);
  cursor: pointer;
}

.ui-widget .ui-menu-item a {
  color: rgb(119, 119, 119);
}

.ui-widget .ui-menu-item a.ui-state-focus {
  border: none;
  color: rgb(0, 0, 0);
}

* html .ui-autocomplete {
  height: 300px;
}

.ui-autocomplete {
  max-height: 300px;
  width: 300px;
  overflow-y: auto;
  /* prevent horizontal scrollbar */
  overflow-x: hidden;
}

.ui-widget .ui-menu-item {
  background: rgb(255, 255, 255);
}

#leadAppointmentForm, #closeCallForm, #recontactForm {
  max-height: 100%;
  overflow: auto;
  z-index: 555;
  width: 500px;
}
#leadAppointmentForm .title, #closeCallForm .title, #recontactForm .title {
  margin-bottom: 20px;
  margin-top: 10px;
}
#leadAppointmentForm .form, #closeCallForm .form, #recontactForm .form {
  width: 260px;
  margin: auto;
}
#leadAppointmentForm .form ul.errors li, #closeCallForm .form ul.errors li, #recontactForm .form ul.errors li {
  line-height: 1.5em;
  margin-top: 0.5em;
}
#leadAppointmentForm .form-line, #closeCallForm .form-line, #recontactForm .form-line {
  margin-bottom: 20px;
  width: 100%;
}
#leadAppointmentForm #appointmentDate, #closeCallForm #appointmentDate, #recontactForm #appointmentDate {
  margin-bottom: 20px;
}
#leadAppointmentForm #appointmentDate .form-line, #closeCallForm #appointmentDate .form-line, #recontactForm #appointmentDate .form-line {
  margin-bottom: 0;
}
#leadAppointmentForm .hour, #closeCallForm .hour, #recontactForm .hour {
  margin-left: 5px;
  margin-right: 5px;
}
#leadAppointmentForm .schedule, #closeCallForm .schedule, #recontactForm .schedule {
  font-size: 10px;
}
#leadAppointmentForm .schedule .name, #closeCallForm .schedule .name, #recontactForm .schedule .name {
  width: 15%;
}
#leadAppointmentForm .schedule > div, #closeCallForm .schedule > div, #recontactForm .schedule > div {
  height: 16px;
}
#leadAppointmentForm .schedule .hours > div, #closeCallForm .schedule .hours > div, #recontactForm .schedule .hours > div {
  line-height: 17px;
}
#leadAppointmentForm .answers, #closeCallForm .answers, #recontactForm .answers {
  text-align: center;
}
#leadAppointmentForm .button-default, #closeCallForm .button-default, #recontactForm .button-default {
  width: 100px;
  font-size: 14px;
  display: inline-block;
  margin-left: 10px;
  margin-right: 10px;
}

#leadAppointmentForm .schedule {
  position: relative;
  min-height: 196px;
}
#leadAppointmentForm .schedule .common-loader {
  position: absolute;
  width: 100%;
}
#leadAppointmentForm .schedule .name {
  width: 18%;
}

#leadAppointmentBackground, #closeCallBackground, #recontactBackground {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.8);
  z-index: 500;
}

.current-date {
  position: absolute;
  background: rgba(190, 240, 224, 0.5);
  background: repeating-linear-gradient(-45deg, rgba(30, 117, 88, 0.1), rgba(30, 117, 88, 0.1) 3px, rgba(190, 240, 224, 0.7) 3px, rgba(190, 240, 224, 0.7) 6px);
  border-radius: 16px;
  height: 100%;
  width: 16px;
  top: 0;
}

.event-from-calendar {
  position: absolute;
  background: rgba(241, 204, 0, 0.8);
  border-radius: 16px;
  height: 100%;
  top: 0;
}
.event-from-calendar.--absence, .event-from-calendar.--leave {
  background: rgba(238, 52, 50, 0.8);
}

.event-w-0 {
  width: 0%;
}

.event-w-1 {
  width: 1%;
}

.event-w-2 {
  width: 2%;
}

.event-w-3 {
  width: 3%;
}

.event-w-4 {
  width: 4%;
}

.event-w-5 {
  width: 5%;
}

.event-w-6 {
  width: 6%;
}

.event-w-7 {
  width: 7%;
}

.event-w-8 {
  width: 8%;
}

.event-w-9 {
  width: 9%;
}

.event-w-10 {
  width: 10%;
}

.event-w-11 {
  width: 11%;
}

.event-w-12 {
  width: 12%;
}

.event-w-13 {
  width: 13%;
}

.event-w-14 {
  width: 14%;
}

.event-w-15 {
  width: 15%;
}

.event-w-16 {
  width: 16%;
}

.event-w-17 {
  width: 17%;
}

.event-w-18 {
  width: 18%;
}

.event-w-19 {
  width: 19%;
}

.event-w-20 {
  width: 20%;
}

.event-w-21 {
  width: 21%;
}

.event-w-22 {
  width: 22%;
}

.event-w-23 {
  width: 23%;
}

.event-w-24 {
  width: 24%;
}

.event-w-25 {
  width: 25%;
}

.event-w-26 {
  width: 26%;
}

.event-w-27 {
  width: 27%;
}

.event-w-28 {
  width: 28%;
}

.event-w-29 {
  width: 29%;
}

.event-w-30 {
  width: 30%;
}

.event-w-31 {
  width: 31%;
}

.event-w-32 {
  width: 32%;
}

.event-w-33 {
  width: 33%;
}

.event-w-34 {
  width: 34%;
}

.event-w-35 {
  width: 35%;
}

.event-w-36 {
  width: 36%;
}

.event-w-37 {
  width: 37%;
}

.event-w-38 {
  width: 38%;
}

.event-w-39 {
  width: 39%;
}

.event-w-40 {
  width: 40%;
}

.event-w-41 {
  width: 41%;
}

.event-w-42 {
  width: 42%;
}

.event-w-43 {
  width: 43%;
}

.event-w-44 {
  width: 44%;
}

.event-w-45 {
  width: 45%;
}

.event-w-46 {
  width: 46%;
}

.event-w-47 {
  width: 47%;
}

.event-w-48 {
  width: 48%;
}

.event-w-49 {
  width: 49%;
}

.event-w-50 {
  width: 50%;
}

.event-w-51 {
  width: 51%;
}

.event-w-52 {
  width: 52%;
}

.event-w-53 {
  width: 53%;
}

.event-w-54 {
  width: 54%;
}

.event-w-55 {
  width: 55%;
}

.event-w-56 {
  width: 56%;
}

.event-w-57 {
  width: 57%;
}

.event-w-58 {
  width: 58%;
}

.event-w-59 {
  width: 59%;
}

.event-w-60 {
  width: 60%;
}

.event-w-61 {
  width: 61%;
}

.event-w-62 {
  width: 62%;
}

.event-w-63 {
  width: 63%;
}

.event-w-64 {
  width: 64%;
}

.event-w-65 {
  width: 65%;
}

.event-w-66 {
  width: 66%;
}

.event-w-67 {
  width: 67%;
}

.event-w-68 {
  width: 68%;
}

.event-w-69 {
  width: 69%;
}

.event-w-70 {
  width: 70%;
}

.event-w-71 {
  width: 71%;
}

.event-w-72 {
  width: 72%;
}

.event-w-73 {
  width: 73%;
}

.event-w-74 {
  width: 74%;
}

.event-w-75 {
  width: 75%;
}

.event-w-76 {
  width: 76%;
}

.event-w-77 {
  width: 77%;
}

.event-w-78 {
  width: 78%;
}

.event-w-79 {
  width: 79%;
}

.event-w-80 {
  width: 80%;
}

.event-w-81 {
  width: 81%;
}

.event-w-82 {
  width: 82%;
}

.event-w-83 {
  width: 83%;
}

.event-w-84 {
  width: 84%;
}

.event-w-85 {
  width: 85%;
}

.event-w-86 {
  width: 86%;
}

.event-w-87 {
  width: 87%;
}

.event-w-88 {
  width: 88%;
}

.event-w-89 {
  width: 89%;
}

.event-w-90 {
  width: 90%;
}

.event-w-91 {
  width: 91%;
}

.event-w-92 {
  width: 92%;
}

.event-w-93 {
  width: 93%;
}

.event-w-94 {
  width: 94%;
}

.event-w-95 {
  width: 95%;
}

.event-w-96 {
  width: 96%;
}

.event-w-97 {
  width: 97%;
}

.event-w-98 {
  width: 98%;
}

.event-w-99 {
  width: 99%;
}

.event-w-100 {
  width: 100%;
}

.event-l-0 {
  left: 0%;
}

.event-l-1 {
  left: 1%;
}

.event-l-2 {
  left: 2%;
}

.event-l-3 {
  left: 3%;
}

.event-l-4 {
  left: 4%;
}

.event-l-5 {
  left: 5%;
}

.event-l-6 {
  left: 6%;
}

.event-l-7 {
  left: 7%;
}

.event-l-8 {
  left: 8%;
}

.event-l-9 {
  left: 9%;
}

.event-l-10 {
  left: 10%;
}

.event-l-11 {
  left: 11%;
}

.event-l-12 {
  left: 12%;
}

.event-l-13 {
  left: 13%;
}

.event-l-14 {
  left: 14%;
}

.event-l-15 {
  left: 15%;
}

.event-l-16 {
  left: 16%;
}

.event-l-17 {
  left: 17%;
}

.event-l-18 {
  left: 18%;
}

.event-l-19 {
  left: 19%;
}

.event-l-20 {
  left: 20%;
}

.event-l-21 {
  left: 21%;
}

.event-l-22 {
  left: 22%;
}

.event-l-23 {
  left: 23%;
}

.event-l-24 {
  left: 24%;
}

.event-l-25 {
  left: 25%;
}

.event-l-26 {
  left: 26%;
}

.event-l-27 {
  left: 27%;
}

.event-l-28 {
  left: 28%;
}

.event-l-29 {
  left: 29%;
}

.event-l-30 {
  left: 30%;
}

.event-l-31 {
  left: 31%;
}

.event-l-32 {
  left: 32%;
}

.event-l-33 {
  left: 33%;
}

.event-l-34 {
  left: 34%;
}

.event-l-35 {
  left: 35%;
}

.event-l-36 {
  left: 36%;
}

.event-l-37 {
  left: 37%;
}

.event-l-38 {
  left: 38%;
}

.event-l-39 {
  left: 39%;
}

.event-l-40 {
  left: 40%;
}

.event-l-41 {
  left: 41%;
}

.event-l-42 {
  left: 42%;
}

.event-l-43 {
  left: 43%;
}

.event-l-44 {
  left: 44%;
}

.event-l-45 {
  left: 45%;
}

.event-l-46 {
  left: 46%;
}

.event-l-47 {
  left: 47%;
}

.event-l-48 {
  left: 48%;
}

.event-l-49 {
  left: 49%;
}

.event-l-50 {
  left: 50%;
}

.event-l-51 {
  left: 51%;
}

.event-l-52 {
  left: 52%;
}

.event-l-53 {
  left: 53%;
}

.event-l-54 {
  left: 54%;
}

.event-l-55 {
  left: 55%;
}

.event-l-56 {
  left: 56%;
}

.event-l-57 {
  left: 57%;
}

.event-l-58 {
  left: 58%;
}

.event-l-59 {
  left: 59%;
}

.event-l-60 {
  left: 60%;
}

.event-l-61 {
  left: 61%;
}

.event-l-62 {
  left: 62%;
}

.event-l-63 {
  left: 63%;
}

.event-l-64 {
  left: 64%;
}

.event-l-65 {
  left: 65%;
}

.event-l-66 {
  left: 66%;
}

.event-l-67 {
  left: 67%;
}

.event-l-68 {
  left: 68%;
}

.event-l-69 {
  left: 69%;
}

.event-l-70 {
  left: 70%;
}

.event-l-71 {
  left: 71%;
}

.event-l-72 {
  left: 72%;
}

.event-l-73 {
  left: 73%;
}

.event-l-74 {
  left: 74%;
}

.event-l-75 {
  left: 75%;
}

.event-l-76 {
  left: 76%;
}

.event-l-77 {
  left: 77%;
}

.event-l-78 {
  left: 78%;
}

.event-l-79 {
  left: 79%;
}

.event-l-80 {
  left: 80%;
}

.event-l-81 {
  left: 81%;
}

.event-l-82 {
  left: 82%;
}

.event-l-83 {
  left: 83%;
}

.event-l-84 {
  left: 84%;
}

.event-l-85 {
  left: 85%;
}

.event-l-86 {
  left: 86%;
}

.event-l-87 {
  left: 87%;
}

.event-l-88 {
  left: 88%;
}

.event-l-89 {
  left: 89%;
}

.event-l-90 {
  left: 90%;
}

.event-l-91 {
  left: 91%;
}

.event-l-92 {
  left: 92%;
}

.event-l-93 {
  left: 93%;
}

.event-l-94 {
  left: 94%;
}

.event-l-95 {
  left: 95%;
}

.event-l-96 {
  left: 96%;
}

.event-l-97 {
  left: 97%;
}

.event-l-98 {
  left: 98%;
}

.event-l-99 {
  left: 99%;
}

.event-l-100 {
  left: 100%;
}

.ui-datepicker-calendar .ui-state-highlight {
  background-color: rgba(0, 1, 104, 0.1);
}

.ui-datepicker-calendar .ui-state-active {
  background-color: rgb(190, 240, 224);
}

#emailPopin {
  width: 80%;
  max-width: 980px;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 10px;
  padding: 0 0 20px 0;
  z-index: 150;
}
#emailPopin .buttons {
  max-width: 250px;
  margin: 20px auto 5px;
}

#closeCallContainer {
  z-index: 501;
}
#closeCallContainer .common-loader {
  width: 50px;
}

.call-closed {
  width: 300px;
  padding: 20px;
  text-align: center;
}
.call-closed .button-default {
  width: fit-content;
  display: block;
  margin: 20px auto 0;
}

.new-players-section .call-player-select {
  width: 280px;
  cursor: pointer;
}

.new-players-section .audio-player {
  width: 275px;
}

.transfer-box.disabled {
  opacity: 0.5;
  cursor: default;
}
.transfer-box.disabled span {
  cursor: default;
}