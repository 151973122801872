@import "colors";
@import "interests";
$in: chartSky(0.4);
$wcb: chartSpark(0.6);
$out: chartFlash(0.6);
$other: rgba(204, 204, 204, 1);

body {
  background-color: #fff;
}

.button-default {
  border-radius : 3px;
}

header {
  h1 {
    width: 100%;
    height: 56px;
    color:transparent;
    background: #F2F0E4 url('../images/v2/logo-scorimmo-noir.svg') no-repeat center center;
    background-size: 137px 40px;
    margin-bottom: 40px;
  }
}

.container {
  max-width: 1660px;
}

.flash-message {
  margin-left: 0;
  margin-right: 0;
  .button-default {
    text-decoration: none;
    float: right;
  }
  p {
    width: 92%;
  }
}

input[type=text], input[type=number], input[type=url], input[type=password], input[type=email], input[type=phone], input[type=time], select[class=new-select] {
  height: 26px;
}

.--nowrap {
  white-space: nowrap;
}

.left-content, .right-content {
  width: 300px;
  height: fit-content;
  position: sticky;
  top: 90px;
  &.--scrolled {
    top: 10px
  }
  .transparent {
    height: 200px;
    width: 1px;
  }
}
.left-content {
  z-index: 50;
}
.right-content {
  margin-left: 40px;

  .transparent {
    height: 220px;
  }
}
.middle-content {
  width: 100%;
  max-width: 980px;
  margin-left: 40px;
  height: fit-content;
  .primary {
    max-width: 640px;
    width: 100%;
  }
  .secondary {
    max-width: 300px;
    margin: 0 10px 10px 40px;
  }
}

.select2-container {
  background-color: #fff;
  box-shadow: 1px 2px 2px 0 rgba(0, 0, 0, 0.45);

  .select2-choice {
    border-radius: 0;

    & > .select2-chosen {
      max-width: 69%;
      min-width: 0;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    &.select2-default {
      .select2-chosen {
        color: #bbb
      }
    }
  }

  &.select2-container-disabled {
    .select2-choice {
      border: 0;
      background-color: #ddd;
      .select2-arrow {
        background-color: #fff;
      }
      & > .select2-chosen {
        color: rgb(84, 84, 84);
      }
      .select2-arrow {
        background-color: #ddd;
      }
    }
  }
}


input[type=text], input[type=email], input[type=url], input[type=number], input[type=time], select[class=new-select], select[class=number], textarea, .select2-container {
  box-shadow: 1px 2px 2px 0 rgba(0, 0, 0, .45);
  &:disabled {
    background-color: #ddd;
    color: rgb(84, 84, 84);
  }
}


.lead-form, .contact-form {

  .lead-form-loader {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0,0,0,.8) url('../images/loader.svg') no-repeat center center;
    background-size: 100px;
    z-index: 1500;
  }

  .pos-name {
    text-align: center;
    font-weight: bold;
    font-size: 16px;
    height: 40px;
    line-height: 40px;
    background-color: $in;

    &.--other {
      background-color: $other;
    }
    &.--wcb {
      background-color: $wcb;
    }
    &.--jda {
      background-color: $out;
    }
  }
  .affected {
    background: chartTwinkle(0.4);
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    font-weight:bold;
    font-size: 15px;
  }
  .form-line {
    margin-bottom: 20px;
    width: 100%;
    &.--zipcode {
      width: 125px;
    }

    &.--quarter {
      flex-basis: calc(24.5% - 40px);
      .select2-container {
        max-width: 123px;
      }
      &:first-child {
        flex-basis: calc(27% - 40px);
      }
    }
    &.--two-quarter {
      flex-basis: calc(49% - 40px);
    }
    &.--three-quarter {
      flex-basis: calc(73% - 40px);
    }
    &.--half {
      flex-basis: calc(50% - 40px);
    }

    &.phone {
      position: relative;
      .call-number {
        font-size: 0;
        position: absolute;
        top: 4px;
        right: 2px;
        .button-icon {
          font-size: 14px;
          display: inline-block;
        }
      }
    }
    &.former {
      .switch-container {
        margin-left: 0;
      }
    }
  }
  .--flex {
    .form-line {
      margin-right: 40px;
    }
    &.--g-20 {
      .form-line {
        margin-right: 20px;
      }
    }
  }

  .section {
    h3 {
      margin-bottom: 0;
    }
    .section-content {
      margin-top: 15px;
    }
    &.--comments {
      header .button-default {
        width: 100px;
        padding: 0;
        margin: 0 auto 20px;
        font-size: 12px;
        text-align: center;
        position: absolute;
        line-height: 19px;
        font-weight: 500;
      }
    }
  }

  .properties {
    .button-default {
      width: fit-content;
      display: block;
      text-align: center;
      color: white;
      background-color: chartSky();
      filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
      cursor: pointer;
      padding: 0 13px;
      line-height: 19px;
      font-size: 13.3px;
      margin: 0;
    }
  }
  .property {
    .link {
      font-size: 16px;
      margin-top: 3px;
      margin-left: 5px;
    }
    &:only-of-type {
      .remove-button-wrapper {
        display: none;
      }
    }


    &:not(:first-child) {
      border-top: 1px solid $other;
      padding-top: 20px;
    }
    .content {
      width: 100%;
    }
  }

  .sliders {
    .switch-container {
      margin-left: 0;
    }
  }
  .switch-container {
    display: inline-block;
  }
  .multiple {
    .form-line:not(:first-child) {
      margin-left: 20px;
    }
    .form-line {
      margin-right: 0;
    }
  }
  .select2 {
    max-width: 100%;
  }
  .buttons {
    bottom: 0;
    position: fixed;
    width: 260px;
    border: solid 1px rgba(120,120,120, .4);
    .button-default {
      width: 200px;
      padding: 2px 10px;
      margin: 0 auto 20px;
      font-size: 14px;
      text-align: center;
    }
    a.button-default {
      width: 180px;
    }
    .button-reopen-lead {
      line-height: inherit;
      top: auto;
      display: block;
      padding-left: 0;
      padding-right: 0;
      text-align: center;
    }
  }
  .other {
    .button-default {
      width: 200px;
      padding: 2px 10px;
      margin: 0 auto 20px;
      font-size: 14px;
      text-align: center;
    }
    a.button-default {
      width: 180px;
    }
  }
  .email-buttons {
    button {
      margin: 0 10px;
    }
  }

  .left-content .section-content {
    line-height: 18px;
  }

  #lead_info {
    .address, .contact-info {
      margin-bottom: 20px;
    }
    .contact-info {
      width: 100%;
      .button-icon {
        font-size: 12px;
        display: inline-block;
        width: 16px;
        height: 16px;
      }
      .link {
        font-size: 16px;
        width: 25px;
        height: 25px;
      }
    }
  }

  .hours {
    th {
      font-weight: normal;
      text-align: left;
      padding-right: 20px;
    }
    .closed {
      color: chartFirework();
      text-align: center;
    }
    .current {
      background-color: #BEF0E0;
      &.--closed {
        background-color: chartFirework(0.2);
      }
    }
  }

  #lead_cities table {
    width: 100%;
  }

  #meteo {
    background: none;
    width: 60px;
    float: right;
    margin-left: 20px;
    text-align: right;
    .temp {
      font-size: 10px;
      width: auto;
      display: block;
      line-height: 10px;
      color: rgba(119, 119, 119, 1);
    }
  }

  .pos-note {
    padding: 10px 0;
    border-top: 1px solid #ccc;
    &.important {
      background: rgba(241, 204, 0, 0.2);
    }
    &__date {
      font-size: 10px;
      color: rgba(119, 119, 119, 1);
    }
  }

  .history {
    .common-loader {
      img {
        width: 50px;
      }
    }
    .content {
      padding-left: 0;
      padding-right: 0;
    }
  }

  #lead_interest {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    gap: 10px;
    padding-bottom: 20px;
    input[type="radio"] {
      opacity: 0;
      position: fixed;
      width: 0;
      &:active:not(:disabled) + label {
        box-shadow: inset 0 4px 4px rgba(0, 0, 0, 0.25);
      }
      &:disabled + label {
        cursor: default;
      }
    }
    label {
      align-self: stretch;
      flex: 1;
      background-color: #ccc;
      color: #fff;
      font-family: BebasNeue, sans-serif;
      font-size: 20px;
      line-height: 20px;
      border-radius: 5px;
      padding: 10px 20px;
      height: 40px;
      text-align: center;
      text-transform: uppercase;
      box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      &.required:after {
        content: "";
      }
      &:hover {
        background-color: #dfd;
      }
    }



    @each $id, $active, $checked in $interests {

      &_#{$id}:hover, &_#{$id}:active {
        & + label {
          background-color: #{$active}
        }
      }
      &_#{$id}:checked {
        & + label {
          background-color: #{$checked}
        }
      }

    }
  }

  #lead_purpose {
    label {
      display: none;
    }
  }

  .by-interest {
    display: none;
    &.--email, &.--call {
      display: block;
    }
  }

  .inquiries {
    flex-wrap: wrap;
    .form-line {
      flex: 0 0 calc(50% - 40px);
      box-sizing: border-box;
    }
    .select2-container .select2-choice > .select2-chosen {
      max-width: 100%;
    }
  }

  @each $id, $active, $checked in $interests {

    &[data-interest=#{$id}] {
      .interest-#{$id}, .interest-all {
        display: block;
        &.--flex {
          display: flex;
        }
      }
      #lead_purpose input.interest-#{$id} + label {
        display: inline-block;
      }
    }

  }

  .record-section {
    position: relative;
    .overlay {
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      position: absolute;
      background: rgba(0,0,0,.8) url('../images/loader.svg') no-repeat center center;
      background-size: 50px;
      z-index: 10;
      border-radius: 5px;

    }
  }

  .new-players-section .call-player-select {
    width: 220px;
    margin-right: 10px;
  }
  .switch-container.record {
    margin: 0 20px;
  }
  .call-player-wrapper .--flex {
    margin-bottom: 10px;
  }


  .answer {
    textarea {
      height: 200px;
    }
  }

  .breadcrumb-notes {
    .note {
      background: #DDDDDD;
      border-radius: 5px;
      color: #555555;
      padding: 5px 15px;
      line-height: 18px;
      margin-bottom: 20px;
      .date-author {
        color: rgba(85, 85, 85, 0.5);
        font-size: 10px;
      }
    }
  }

  .sort-search {
    &.--current {
      border-bottom: 2px solid rgba(211, 27, 27, 1)!important;
      background-color: rgba(211,27,27,.1);
    }
  }

  #lead_team {

    .section-content {
      min-width: 860px;
    }
    .errors {
      margin-left: 20px;
      margin-bottom: 10px;
    }
  }

  .sellers {
    padding-left: 0!important;
    padding-right: 0!important;
    .header {
      padding-left: 20px;
      padding-right: 20px;
      margin-bottom: 10px;
      .prioritization {
        color: chartFirework();
        margin-left: 15px;
        margin-right: 5px;
      }
    }
    .content {
      line-height: 16px;
      .seller-line {
        padding: 10px 20px;
        flex-wrap: wrap;
        cursor: pointer;
        &:not(:first-child) {
          border-top: 1px solid $other;
        }
      }
    }
    .seller-line:hover {
      background-color: chartShimmer(0.4);
    }
    .seller-selected, .seller-selected:hover {
      background-color: chartShimmer(0.8);
    }
    .seller-line, .header {
      & > div {
        margin-right: 1%;
      }
      &__info {
        width: 31%;
        display: inline-flex;
        gap: 5px;
      }
      &__pos {
        display: none;
      }
      &__affect-code {
        width: 7%;
      }
      &__sector {
        width: 15%;
      }
      &__source {
        width: 14%;
      }
      &__phone {
        width: 14%;
      }
      &__last_lead {
        width: 12%;
        text-align: center;
      }
      &__position {
        width: 5%;
        text-align: center;
      }
      &__days-off {
        width: 100%;
      }
      &__leaves {
        width: 100%;
        color: #EE3432 !important;
        font-size: 11px;
      }

    }

    &.--group {
      .seller-line, .header {
        &__pos {
          width: 14%;
          display: block;
        }

        &__info {
          width: 25%;
        }
        &__affect-code {
          width: 7%;
        }
        &__sector {
          width: 12%;
        }
        &__source {
          width: 12%;
        }
        &__phone {
          width: 12%;
        }
        &__last_lead {
          width: 7%;
          text-align: center;
        }
      }
    }

    .header {
      .actions {
        margin-left: 5px;
        span {
          color: rgba(119, 119, 119, 0.4);
          cursor: pointer;
          &.--current {
            color: rgba(119, 119, 119, 1);
          }
        }
      }
    }

    .absent  {
      .leaves, .seller-identity {
        color: #EE3432!important;
      }
    }
    .phone-link a, .email-link a {
      color: chartSky();
      font-size: 12px;
      display: inline-block;
      width: 16px;
      height: 16px;
    }
    .comments {
      color: chartSky();
      font-size: 11px;
    }
    .leaves, .role {
      color: rgba(119, 119, 119, 1);
      font-size: 11px;
    }
    .role {
      font-style: italic;
    }

    &.--prioritization {
      .seller-line, .header {
        &__info {
          width: 26%;
        }
      }
      &.--group {
        .seller-line, .header {
          &__info {
            width: 23%;
          }
        }
      }
    }
  }

  ul.errors li {
    line-height: 1.5em;
    margin-top: 0.5em;
  }

}

.email-no-content {
  margin: 20px;
  text-align: center;
}

span.tag {
  -moz-border-radius: 6px;
  border-radius: 6px;
  -webkit-border-radius: 6px;
  border: 1px solid rgba(120,120,120,0.1);
  background-color: rgba(100,100,100,.05);
  padding: 0 2px;
  margin: 0 2px 2px 0;
  white-space: nowrap;
  display: inline-block;
}

.confirmation {
  width: 620px;
  margin: 0 auto;
  position: absolute;
  top: calc(50% - 65px);
  left: 50%;
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  h3 {
    margin-bottom: 40px;
  }
  .section-content {
    text-align: center;
  }
  .button-default {
    width: fit-content;
    padding: 2px 20px;
    margin: 40px auto 20px;
    font-size: 14px;
  }
}


.ui-autocomplete {
  max-width: 500px;
  .ui-menu-item a {
    cursor: pointer;
  }
}


.ui-widget .ui-menu-item {
  padding: 0 5px;
  background: rgb(243,243,243);
  border-radius: 3px;
  margin-bottom: 0;
  transition: all ease-out 0.2s;
}

.ui-widget .ui-menu-item:hover {
  background:rgba(190, 240, 224, .4);
  cursor:pointer;

}

.ui-widget .ui-menu-item a {
  color: rgb(119,119,119);
}

.ui-widget .ui-menu-item a.ui-state-focus {
  border:none;
  color: rgb(0,0,0)
}
* html .ui-autocomplete {
  height: 300px;
}
.ui-autocomplete {
  max-height: 300px;
  width: 300px;
  overflow-y: auto;
  /* prevent horizontal scrollbar */
  overflow-x: hidden;
}
.ui-widget .ui-menu-item {
  background: rgb(255, 255, 255);
}

#leadAppointmentForm, #closeCallForm, #recontactForm {
  max-height: 100%;
  overflow: auto;
  z-index: 555;
  width: 500px;
  .title {
    margin-bottom: 20px;
    margin-top: 10px;
  }
  .form {
    width: 260px;
    margin: auto;
    ul.errors li {
      line-height: 1.5em;
      margin-top: 0.5em;
    }
  }
  .form-line {
    margin-bottom: 20px;
    width: 100%;
  }
  #appointmentDate {
    margin-bottom: 20px;
    .form-line {
      margin-bottom: 0;
    }
  }
  .hour {
    margin-left: 5px;
    margin-right: 5px;
  }
  .schedule {
    font-size: 10px;
    .name {
      width: 15%;
    }
    & > div {
      height: 16px;
    }
    .hours > div {
      line-height: 17px;
    }
  }
  .answers {
    text-align: center;
  }
  .button-default {
    width: 100px;
    font-size: 14px;
    display: inline-block;
    margin-left: 10px;
    margin-right: 10px;
  }
}

#leadAppointmentForm .schedule {
  position: relative;
  min-height: 196px;
  .common-loader {
    position: absolute;
    width: 100%;
  }
  .name {
    width: 18%;
  }
}
#leadAppointmentBackground, #closeCallBackground, #recontactBackground {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0,0,0,.8);
  z-index: 500;
}

.current-date {
  position: absolute;
  background: rgba(190, 240 ,224, .5);
  background: repeating-linear-gradient(
        -45deg,
        rgba(30, 117, 88, .1),
        rgba(30, 117, 88, .1) 3px,
        rgba(190, 240 ,224, .7) 3px,
        rgba(190, 240 ,224, .7) 6px
  );
  border-radius: 16px;
  height: 100%;
  width: 16px;
  top: 0;
}

@mixin width-classes($prefix, $step, $max) {
  @for $i from 0 through $max {
    // Calcule le pourcentage en utilisant la formule
    $percentage: calc($i * $step / $max);

    // Formater le pourcentage avec deux décimales
    $formatted-width: calc($percentage * 100%);
    $formatted-width: calc(round(calc($formatted-width * 100)) / 100);

    .#{$prefix}#{$i} {
      width: $formatted-width;
    }
  }
}
@mixin left-classes($prefix, $step, $max) {
  @for $i from 0 through $max {
    // Calcule le pourcentage en utilisant la formule
    $percentage: calc($i * $step / $max);

    // Formater le pourcentage avec deux décimales
    $formatted-left: calc($percentage * 100%);
    $formatted-left: calc(round(calc($formatted-left * 100)) / 100);

    .#{$prefix}#{$i} {
      left: $formatted-left;
    }
  }
}

.event-from-calendar {
  position: absolute;
  background: chartFlash(.8);
  border-radius: 16px;
  height: 100%;
  top: 0;
  &.--absence, &.--leave {
    background: chartFirework(.8);
  }
}
@include width-classes('event-w-', 1, 100);
@include left-classes('event-l-', 1, 100);


.ui-datepicker-calendar .ui-state-highlight {
  background-color: rgba(0,1,104,.1);
}
.ui-datepicker-calendar .ui-state-active {
  background-color: rgba(190, 240, 224, 1);
}

#emailPopin {
  width: 80%;
  max-width: 980px;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 10px;
  padding: 0 0 20px 0;
  z-index: 150;

  .buttons {
    max-width: 250px;
    margin: 20px auto 5px;
  }
}

#closeCallContainer {
  z-index: 501;
  .common-loader {
    width: 50px;
  }
}
.call-closed {
  width: 300px;
  padding: 20px;
  text-align: center;
  .button-default {
    width: fit-content;
    display: block;
    margin: 20px auto 0;
  }
}

.new-players-section .call-player-select {
  width: 280px;
  cursor: pointer;
}
.new-players-section .audio-player {
  width: 275px;
}

.transfer-box {
  &.disabled {
    opacity: .5;
    cursor: default;
    span {
      cursor: default;
    }
  }
}
